import axios from "axios";
import { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { DASHBOARD_API_PATH } from "../../network/config/apiPaths";
import { useNavigate } from "react-router-dom";
import { consoleLog } from "../../utils/log-utils";

function AdminDashboard() {
  const [result, setResult] = useState([]);
  const navigate=useNavigate();
  useEffect(() => {
    dashboardApi();
  }, []);
  const dashboardApi = async () => {
    //let data =
    await axios
      .get(API_CONFIG.API_HOST + DASHBOARD_API_PATH.LIST_PATH, {
        headers: API_HEADERS,
      })
      .then((data) => {
        consoleLog("data",data);
        setResult(data.data.result);
      })
      .catch((err) => {
        consoleLog("err",err);
      });
  };
  return (
    <>
      {result && (
        <main className="container-fluid dashboard">
          <div className="row m-3">
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>navigate("/products")}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Products <br /> {result.totalProducts} Nos
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Locations <br /> {result.totalLocations} Nos
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>navigate("/stores")}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Stores <br /> {result.totalStoresCount} Nos
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>navigate("/team")}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Employees <br /> {result.totalEmployees} Nos
              </div>
            </div>
         
          
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>navigate("/customers")}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Customers <br /> {result.totalCustomers} Nos
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3" onClick={()=>navigate("/orders")}>
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Orders <br /> {result.totalOrders} Nos
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
            {/* onClick={()=>navigate("/payments")} */}
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total Payment <br /> {result.totalPayments} INR
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="dashboard-div1 d-flex flex-column justify-content-center">
                Total App Amount <br /> {result.totalAppAmount} INR
              </div>
            </div>
            </div>
        </main>
      )}
    </>
  );
}

export default AdminDashboard;
