import React, { useState } from 'react'
import { FaRegEdit, FaStar } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { STORE_API_PATH } from '../../../../network/config/apiPaths';
import { API_CONFIG, API_HEADERS } from '../../../../network/config/ApiConfig';
import { updateMessage } from '../../../../redux/slice/toastSlice';
import { useDispatch } from 'react-redux';
import { axiosApi } from '../../../../network/service/config/AaxiosUtil';
import { isCustomerSupport, isFranchisLogin } from '../../../../utils/userRoles';
import { getImagePath } from '../../../../utils/util';
import { consoleLog } from '../../../../utils/log-utils';

function ProductItem(props) {
  const [product] = useState(props.item)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  consoleLog("unique store..", product)
  const updatePrice = () => {
    navigate("/updatePrice", { state: { price: product, from: "price" } })
  }
  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await axiosApi
      .post(API_CONFIG.API_HOST + STORE_API_PATH.PRICE_UPDATE, requestBody, {
        API_HEADERS,
      })
      .then((response) => {
        consoleLog("STORE UPODATE", response);
        if (response.status === 200 && response.data.status === true) {
          setItem(item, (item.status = status));
          setitemStatus(status);
        } else {
          dispatch(
            updateMessage({
              display: true,
              message: "Error While updating info",
            })
          );
        }
      });

    // await dispatch(createUpdateAction(requestBody)).then((response) => {
    //   if (response.status == true) {
    //     setItem(product, (product.status = status));
    //     setitemStatus(status);
    //   } else {
    //     dispatch(
    //       updateMessage({
    //         display: true,
    //         message: "Error While updating info",
    //       })
    //     );
    //   }
    // });
  };
  return (
    <>
      <tr>
        <td>{props.sno}</td>
        <td>{product.scName}</td>
        <td ><img width={50} height={50} src={getImagePath(product.image_path)} alt='' /></td>

        <td>{product.product_name}</td>
        <td>{product.qty}</td>
        <td>{product.measure}</td>
        <td>₹{product.price}</td>
        <td>₹{product.offer_price}</td>

        <th scope="col">{product.c_gst}</th>
        <th scope="col">{product.s_gst}</th>
        <td>{product.gsp_percentage}%</td>
        <td><FaStar style={{color:"yellow"}}/> {product.avg_rating}</td>
        {(!isFranchisLogin() && !isCustomerSupport()) && <>
          <td>
            <div className="form-check form-switch d-flex flex-row justify-content-center">

              <input type="checkbox"
                className="form-check-input"
                onChange={onStatusChange}
                checked={itemStatus === "YES" ? true : false}
                role="switch"
              />
            </div>

          </td>
          <td className="align-middle">
            <div className="col-lg-12 d-flex justify-content-center">
              <span
                className="edit-icon-btn"
                onClick={() =>
                  updatePrice()
                }
              >
                <FaRegEdit />
              </span>
            </div>
          </td>
        </>
        }

      </tr>
    </>
  )
}

export default ProductItem