import { useEffect, useState } from "react";

import DbSettlementItem from "./db-settlements-item";

function DbSettlements() {
 
  const [paymentList, setPaymentList] = useState([]);
  const [filterForm,setFilterForm]=useState({
    startDate:"",
    endDate:"",
    month:"",
    location:"",
  })
  const [locationList,setLocationList]=useState([])
  const month = [
    { id: 1, name: "JAN" },
    { id: 2, name: "FEB" },
    { id: 3, name: "MARCH" },
    { id: 4, name: "APRIL" },
    { id: 5, name: "MAY" },
    { id: 6, name: "JUNE" },
    { id: 7, name: "JULY" },
    { id: 8, name: "AUG" },
    { id: 9, name: "SEP" },
    { id: 10, name: "OCT" },
    { id: 11, name: "NOV" },
    { id: 12, name: "DEC" },
  ];

//   useEffect(() => {
//     paymentApiCall();
//     fetchLocation()
//   }, []);
//   const handleChange=(e)=>{
//     setFilterForm({
//       ...filterForm,
//       [e.target.name]:e.target.value
//     })
//   }
//   const paymentApiCall = async () => {
//     let data = await axios.get(
//       API_CONFIG.API_HOST +`Payments/adminPaymentList`,
     
//       {
//         headers: API_HEADERS,
//       }
//     );
//     consoleLog("data", data.data.result);
//     setPaymentList(data.data.result);
//   };

//   const fetchLocation=async()=>{
//     let data=await axios.get(API_CONFIG.API_HOST+LOCATION_API_PATH.LOCATION_LIST_PATH,{
//       headers:API_HEADERS
//     })
//  consoleLog("location list",data.data.result)
//  setLocationList(data.data.result)
//   }
  
  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">DB Settlements</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className="row m-3">
          {/* <div className="col-4 col-md-2">
            <label>Month</label>
            <select 
            value={filterForm.month} 
            onClick={handleChange} 
            name="month" 
            className="form-control"
            >
              <option value={""}>{"< FEB >"}</option>
              {month.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
              
            </select>
          </div>
          <div className="col-4 col-md-2">
            <label>From</label>
            <input type="date" name="startDate" value={filterForm.startDate} onChange={handleChange} className="form-control" />
          </div>
          <div className="col-4 col-md-2">
            <label>To</label>
            <input type="date" name="endDate" value={filterForm.endDate} onChange={handleChange} className="form-control" />
          </div>
          <div className="col-4 col-md-2 top-margin">
            <label>Location</label>
           <select className="form-control" name="location" value={filterForm.location}  onClick={handleChange}>
            <option value="">{"--select city--"}</option>
            {
               locationList && locationList.length>0?
               locationList.map(obj=>{
                return(
                  <option value={obj.location_name}>{obj.location_name}</option>
                )
               }):<>No Data</>
            }
           </select>
          </div> */}
          <div className="col-4 top-margin" style={{marginLeft:"auto"}}>
          <label></label><br/>
          
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  
                 
                  {/* <th scope="col">Settlement ID</th> */}
                  <th scope="col">Date</th>
                  <th scope="col">DB Name</th>
                  <th Scope="col">Total Orders</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">DB amount</th>
                  
                  <th scope="col">Settlement Date</th>
                  <th scope="col">Settlement Id </th>
                  <th scope="col">Action</th>
                  {/* <th scope="col">Payment</th> */}
                  {/* <th scope="col">Date</th>
                  <th scope="col">Transaction ID</th> */}
                </tr>
              </thead>

              <tbody>
               
                        <DbSettlementItem  sno={1} />
                     
              </tbody>
            </table>
            
          </div>
        </div>
      </main>
    </>
  );
}

export default DbSettlements;
