import React, { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { USER_API_PATH } from "../../network/config/apiPaths";

import { axiosApi } from "../../network/service/config/AaxiosUtil";
import AutoComplete from "react-google-autocomplete";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useToastMsg } from "../hooks/useToastMsg";
import axios from "axios";
import { isAdmin, isSuperAdmin } from "../../utils/userRoles";

import { consoleLog } from "../../utils/log-utils";

function AddUser() {
  const [roleList, setRoleList] = useState([
    { id: "10", name: "CMS"},
    // isSuperAdmin()&&{id: "2", name: "ADMIN" },
    { id: "3", name: "MARKETING EXECUTIVE" },
    { id: "4", name: "STORE ADMIN" },
    { id: "6", name: "CUSTOMER_SUPPORT" },
    { id: "7", name: "DELIVERY_BOY" },
    { id: "9", name: "FRANCHISE OWNER" },
  ]);
  const[inputValidation,setInputValidation] = useState(
    {
      place_address:"",
    }
  )

  // User/getNearbyUsers?lat=&lng=&roleId=9

  const [cityName, setCity] = useState("");
  const [areaName, setAreaName] = useState("");
 
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
 
  
  const [inputList, setInputList] = useState({
    role_id: "",
    userName: "",
    phoneNumber: "",
    email: "",
    franchiseOwner:"",
    franchisePercentage:"",
    radius:"",
  });

  const [franchiseUsers,setFranchiseUsers]=useState([]);

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    consoleLog("name...", name);
    setInputList({
      ...inputList,
      [name]: value,
    });
  };

  useEffect(()=>{
    FranchiseOwnerApi();
    consoleLog("franchise...",franchiseUsers)
  },[latitude])

  // const sendNotification=()=>{
  //   addNotification({
  //     title:"sent notification",
  //     message:"successfully sended",
  //     duration:4000,
  //     native:true
  //   })
  // }

  const reset=()=>{
    setInputList(
        {...inputList,
    role_id:"",
    userName:"",
    phoneNumber: "",
    email:"",
    franchiseOwner:"",
    franchisePercentage:"",
    radius:"",
        }
    )
    
  }

  const updatePlaceInfo = (place) => {
    if (place && place.address_components != null) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case "locality":
           setAreaName(place.address_components[i].long_name)
            
            break;
          case "administrative_area_level_3":
          setCity(place.address_components[i].long_name)
            
            
            break;
          // case "country":
          //   setCountry(place.address_components[i].long_name);
          //   break;
        }
      }
    }
    consoleLog("place..", place);
    
    setLatitude(place.geometry.location.lat())
    setLongitude(place.geometry.location.lng())
  };
  const labelStyle = {
    fontWeight: "bold",
   
  };

  const[success,warning]=useToastMsg("Successfully Created","Required all input feilds",reset)
  
  async function CreateUser() {
    let requestData = {
      name: inputList.userName,
      email: inputList.email,
      password: inputList.phoneNumber,
      role_id: inputList.role_id,
      status: "ACTIVE",
      phone: inputList.phoneNumber,
      superUserId:inputList.franchiseOwner,
      dbLat:latitude,
      dbLng:longitude,
      city:areaName,
      radius:inputList.radius,
      foShare:inputList.franchisePercentage,
    };

   const data=await axiosApi.post(
        API_CONFIG.API_HOST + USER_API_PATH.REGISTRATION_PATH,
        requestData,
        {
          headers: API_HEADERS,
        }
      )
      consoleLog("data",data)
      consoleLog("status code",data.status)
      data.status===200 ? success() : warning()
    

    
  }

  const handleChange=(e)=>{
    const {name,value}=e.target;
    setInputValidation({
      [name]:value
    })
  }

 const FranchiseOwnerApi= async ()=>{
    const data=await axios.get(API_CONFIG.API_HOST+`User/getNearbyUsers?lat=${latitude}&lng=${longitude}&roleId=9`,{
      headers:API_HEADERS,
    })
    consoleLog("in api..",data.data.result)
    setFranchiseUsers(data.data.result)
  }

  const SelectFranchiseOwner = () =>{
    
    return(
      <div className="col-12 mt-4">
      <select
      className="form-control"
      name="franchiseOwner"
      value={inputList.franchiseOwner}
      onChange={onChangeEvent}
    >
      <option value={""}>{"--select Franchise Owner--"}</option>
      {franchiseUsers&&franchiseUsers.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.name}
          </option>
        );
      })}
    </select>
    </div>
    )
  }

  const FranchiseOwnerPercentage =()=>{
    return(
      <>
      <div className="col-12">
      <input
            type="number"
            placeholder="franchise percentage"
            name="franchisePercentage"
            value={inputList.franchisePercentage}
            onChange={onChangeEvent}
            className="form-control mt-4"
          />
       <input
            type="number"
            placeholder="radius"
            name="radius"
            value={inputList.radius}
            onChange={onChangeEvent}
            className="form-control mt-4"
          />
          </div>
      </>
    )
  }

  const Validation=()=>{
    
   ( inputList.email==="" ||
    inputList.phoneNumber === "" || inputList.phoneNumber.length!==10||
    inputList.role_id === "" ||
    inputList.userName==="" || areaName==="" || cityName==="" )? warning() : CreateUser()
 
  }

  return (
    <>
    
    <div className="container-fluid dashboard d-flex flex-row justify-content-center">
      <div className="row m-5 add-homeBanner-width">
      <div className="col-12">
            <div className="form-group m-0">
              <label style={labelStyle}>Search City/Town</label>{" "}
              {inputValidation.place_address}
              <AutoComplete
                className="form-control"
                style={{ width: "100%" }}
                name="landmark"
                options={{
                  types: ["geocode", "establishment"],
                }}
                onChange={(e) => handleChange(e)}
                apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}
                onPlaceSelected={(place) => updatePlaceInfo(place)}
                placeholder="Enter city name"
              />
            </div>
          </div>
        <div className="col-12 mt-4">
          <label>Select Role</label>
          <select
            className="form-control"
            name="role_id"
            value={inputList.role_id}
            onChange={onChangeEvent}
          >
            <option value={""}>{"--select role--"}</option>
            {isSuperAdmin() && <option value="2">ADMIN</option>}
            {roleList.map((item, i) => {
              return (
                <option key={i} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
          {inputList.role_id===""&&<span className="text-danger">*Required</span>}
        </div>
        
        
         {inputList.role_id==="7" &&  SelectFranchiseOwner()}
         {inputList.role_id==="9" && FranchiseOwnerPercentage()}
        

        <div className="col-12 mt-4">
          <label>Name</label>
          <input
            className="form-control"
            name="userName"
            value={inputList.userName}
            placeholder="Name"
            type="text"
            onChange={onChangeEvent}
          />
          {inputList.userName===""&&<span className="text-danger">*Required</span>}
        </div>

        <div className="col-12 mt-4">
          <label>Phone Number</label>
          <input
            type="number"
            placeholder="Phone number"
            name="phoneNumber"
            value={inputList.phoneNumber}
            onChange={onChangeEvent}
            className="form-control"
          />
          {inputList.phoneNumber.length===10 ?"":<span className="text-danger">*Enter 10 digit number</span>}
          {consoleLog("length",inputList.phoneNumber.length)}
        </div>
        <div className="col-12 mt-4">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={inputList.email}
            onChange={onChangeEvent}
            className="form-control"
            placeholder="Email Id"
          />
          {inputList.email===""&&<span className="text-danger">*Required</span>}
        </div>
        <div className="col-12 text-center">
          <button className="btn btn-success add-homeBanner-button-width mt-4" onClick={Validation}>
            Submit
          </button>
          <ToastContainer/>
        </div>
      </div>
      {consoleLog("inputfeilds..",inputList)}
    </div>
    {consoleLog("loacation...",cityName,latitude,longitude,areaName)}
    
    </>
  );
}

export default AddUser;
